<template>
  <div class="input-select input-mall" v-on:click="toggleClass">
    <!-- <input type="text" placeholder="Какой торговый комплекс вас интересует? *" class="form__input" name="mall"> -->
    <div class="input-selected">
      <span v-if="selectTitle === null" class="input-mall__selected">{{
        placeholder
      }}</span>
      <span v-else class="input-mall__selected">{{
        getTranslateSelectTitle(selectTitle)
      }}</span>
      <div class="input-arrow-icon" v-bind:class="{ 'is-active': active }">
        <svg
          width="20"
          height="9"
          viewBox="0 0 20 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.78788 0L0 1.36504L10 9L20 1.36504L18.2121 0L10 6.26992L1.78788 0Z"
            fill="#999999"
          />
        </svg>
      </div>
    </div>
    <div class="input-select__dropdown" :class="{ 'is-active': active }">
      <div class="input-select__dropdown-wrapper">
        <div
          v-for="(item, index) in dropdown"
          :key="index"
          class="input-select__item"
          :class="{ active: getActive(item) }"
          @click="getSelected(item)"
        >
          {{ getTranslateDropdown(item) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSelect",
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    dropdown: {
      type: Array,
      required: true,
    },
    attributes: {
      type: Boolean,
      default: false,
    },
    field: {
      type: String,
      required: true,
    },
    sent: {
      type: Boolean,
      default: false,
    },
    translate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
      selectTitle: null,
      selectItem: null,
    };
  },
  watch: {
    sent: {
      handler() {
        if (this.sent) {
          this.selectTitle = null;
        }
      },
    },
  },
  methods: {
    getSelected(item) {
      if (this.attributes) {
        this.selectTitle = item.attributes.name;
      } else {
        this.selectTitle = item[this.field];
      }
      this.selectItem = item;
    },
    toggleClass() {
      this.active = !this.active;
      this.$emit("input", this.selectItem);
    },
    getActive(item) {
      if (this.attributes) {
        if (this.selectItem) {
          return item.id === this.selectItem.id;
        } else {
          return false;
        }
      } else {
        if (this.selectItem) {
          return item.type === this.selectItem.type;
        } else {
          return false;
        }
      }
    },
    getTranslateDropdown(item) {
      if (this.translate) {
        return this.attributes
          ? this.$t(item.attributes[this.field])
          : this.$t(item[this.field]);
      } else {
        return this.attributes ? item.attributes[this.field] : item[this.field];
      }
    },
    getTranslateSelectTitle(item) {
      if (this.translate) {
        return this.$t(item);
      } else {
        return item;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
